import axios from "axios";

function CreateFileHash(file) {
  return new Promise((resolve, reject) => {
    file.arrayBuffer().then((contents) => {
      crypto.subtle
        .digest("SHA-256", contents)
        .then((hashBuffer) => {
          const hashArray = Array.from(new Uint8Array(hashBuffer));
          let fileHash = hashArray
            .map((bytes) => bytes.toString(16).padStart(2, "0"))
            .join("");
          resolve(fileHash);
        })
        .catch((e) => {
          reject("Unable to create file checksum hash: ", e);
        });
    });
  });
}
export default CreateFileHash;
