import { loginApiRequest } from "./authConfig";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const SetupAxios = () => {

    const { instance } = useMsal();
    const navigate = useNavigate();

    console.log("Setup Axios Env: " + process.env.NODE_ENV);

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        axios.defaults.baseURL = "https://localhost:7027/api/";
    else
        axios.defaults.baseURL = "/api";

    axios.interceptors.request.use(
        async (response) => {
            const account = instance.getActiveAccount();
            const msalResponse = await instance.acquireTokenSilent({
                ...loginApiRequest,
                account: account,
            });
            response.headers['Authorization'] = `Bearer ${msalResponse.accessToken}`;
            return response;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        // Do something with response data
        return response;
    }, function (error) {
        // Do something with response error
        if (error.response.status === 401 || error.response.status === 403) {
            console.log('No access ' + error.response.status);
            navigate("/AccessDenied");
        }

        return Promise.reject(error);
    });
}

export default SetupAxios