import React from 'react';

const AccessDenied = () => {
    return (
        <div>
            Sorry, you do not have access to this page
        </div>
    );
};

export default AccessDenied;