import React, { useState } from "react";
import {
    Container,
    Nav,
    Navbar,
    NavDropdown
} from "react-bootstrap";
import "./Header.css";

const Header = () => {
    return (
        <Container>
        </Container>
    );
};

export default Header;