import React, { useState } from "react";
import {
    Container,
    Nav,
    Navbar,
    NavDropdown
} from "react-bootstrap";
import "./Menu.css";
import IsQAEnvironment from "./functions/IsQAEnvironment.js";

const Menu = (props) => {
    const menuChangeHandler = (eventKey) => {
        if (typeof eventKey === 'string') {
            props.onNavItemSelected(eventKey);
        }
        else {
            props.onNavItemSelected("0");
        }
    };

    return (
        <Navbar bg="danger" variant="dark" expand="lg" className={IsQAEnvironment(props.ApplicationSettings) ? "mb-3 bg-qa" : "bg-company-red mb-3"}>
            <Container>
                <Navbar.Brand className="align-center" onClick={menuChangeHandler}>File Transfer System</Navbar.Brand>
                {IsQAEnvironment(props.ApplicationSettings) ? <span className="pull-right navbar-brand">Test Environment</span>:''}
            </Container>
        </Navbar>
    );
};

export default Menu;