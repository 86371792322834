import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "d78334aa-ebde-4377-8383-00b9fc844cd8", // Client ID
    authority:
      "https://login.microsoftonline.com/8da74ad6-a2e3-4087-96e3-e8f1dc8f82cc", // Tenant ID of the React.JS App Registration
    redirectUri: "https://epcfiles-qa.enmax.com",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
          // do nothing
        }
      },
    },
  },
};

// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
  scopes: ["api://d78334aa-ebde-4377-8383-00b9fc844cd8/api.scope"],
};
