import React, { useEffect, useState } from 'react';
import Main from "./pages/Main";
import AccessDenied from "./pages/AccessDenied";
import "./App.css";
import { useMsal } from "@azure/msal-react";
import { loginApiRequest } from "./authConfig";
import { useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { Routes, Route } from "react-router-dom";
import NoPage from "./pages/NoPage";
import SetupAxios from "./SetupAxios";

const App = () => {
    const roleTokenRegex = "^.*login.windows.net((?!(token)).)*$";
    const [userRoles, setUserRoles] = useState([]);
    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress } = useMsal();
    SetupAxios();

    if (inProgress === InteractionStatus.None && !isAuthenticated) {
        instance.loginRedirect(loginApiRequest).catch((e) => {
            console.log(e);
        });
    }

    useEffect(() => {
        LoadAuthFromStorage();
    }, []);

    const LoadAuthFromStorage = () => {
        var msAuthToken;
        if (userRoles.length == 0) {
            msAuthToken = JSON.parse(LoadStorageByExpression(roleTokenRegex));
            if (msAuthToken != null && msAuthToken != undefined) {
                setUserRoles(instance.getActiveAccount().idTokenClaims?.roles);
            }
        }
    }

    function LoadStorageByExpression(regex) {
        var returnValue = null;
        var values = [],
            keys = Object.keys(localStorage),
            i = keys.length;
        while (i--) {
            if (keys[i].match(regex)) {
                returnValue = localStorage.getItem(keys[i]);
                break;
            }
        }
        return returnValue;
    }

    return (
        <Routes>
            <Route path="/" >
                <Route index element={<Main UserRoles={userRoles} />} />
                <Route path="accessdenied" element={<AccessDenied />} />
                <Route path="*" element={<NoPage />} />
            </Route>
        </Routes>

    );
}

export default App;
