import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";

const Footer = () => {
    return (
        <Container>
            <div className="footer-custom"></div>
            <div>
                <Row>
                    <Col md xs={9}>
                        <small className="text-muted">Developed and maintained by EPC Business Technology Services<br />
                            <strong>Version: 1.0</strong></small>
                    </Col>
                    <Col md xs={3} className="footer-logo">
                        <img src="/logo_red.svg" width="115" />
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default Footer;