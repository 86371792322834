import React, { useState, useEffect } from 'react';
import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import axios from "axios";
import FileUploadForm from '../components/forms/FileUploadForm';

const Main = (props) => {
    const [displayControlKey, setDisplayControlKey] = useState("0");
    const ApplicationSettingsUrl = "ApplicationSettings/GetApplicationSettings";
    const [settingsData, setSettingsData] = useState();

    //passed to the header control to set the state variable when a menu item is selected
    const displayControlHandler = (navKey) => {
        setDisplayControlKey(navKey);
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        const { data } = await axios.get(ApplicationSettingsUrl);
        setSettingsData(data);
    };

    return (
        <div>
            <Header />
            <Menu onNavItemSelected={displayControlHandler} UserRoles={props.UserRoles} ApplicationSettings={settingsData} />
            {displayControlKey === "0" ? <FileUploadForm /> : null}
            <Footer />
        </div>
    );
};

export default Main;