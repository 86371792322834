import React, { useState, useEffect, Fragment, useRef } from "react";
import { ProgressBar } from "react-bootstrap";
import axios from "axios";
import CreateFileHash from "../../utils/FileUtils.js";
import { v4 as uuid4 } from "uuid";
import { fileStatuses } from "../FileStatuses.js";

const FileUploadProgress = (props) => {
  const chunkUrl = "uploadChunks";
  const fileLogUrl = "addFileLog";
  const maxFileSize = 1048576 * 1024; // 1GB file max

  let file = props.file;
  let maxChunkSize = props.maxChunkSize;
  let onUploadComplete = props.onUploadComplete;
  let fileSize = file.size;
  let chunkCount =
    file.size % maxChunkSize == 0
      ? file.size / maxChunkSize
      : Math.floor(file.size / maxChunkSize) + 1;
  let fileToUpload = file;

  const [showProgress, setShowProgress] = useState(false);
  const [counter, setCounter] = useState(1);
  const [beginningOfChunk, setBeginningOfChunk] = useState(0);
  const [endOfChunk, setEndOfChunk] = useState(maxChunkSize);
  const [progress, setProgress] = useState(0);
  const [showFileLimitMsg, setShowFileLimitMsg] = useState(false);
  const [showHashMismatchMsg, setShowHashMismatchMsg] = useState(false);

  const [fileState, setFileState] = useState(null);

  useEffect(() => {
    if (props.fileLogs.length > 0) {
      let currentFileLog = props.fileLogs.find((s) => s.fileName === file.name);
      // ensure that there is a file log entry
      if (currentFileLog) {
        if (
          currentFileLog.fileHashMatch !== null &&
          !currentFileLog.fileHashMatch
        ) {
          // show hash mismatch error message and hide progress bar
          setShowHashMismatchMsg(true);
          setShowProgress(false);

          // set file upload status to failed
          setFileState({
            ...currentFileLog,
            fmeUploadStatus: fileStatuses.failed,
          });
          props.onFileStateChange(file.name, fileStatuses.failed);
        } else {
          setFileState(currentFileLog);
        }
      }
    }
  }, [props.fileLogs]);

  const progressInstance = (
    <ProgressBar animated now={progress} label={`${Math.floor(progress)}%`} />
  );

  // add hash on file upload start
  useEffect(() => {
    if (fileSize > 0) {
      if (fileSize > maxFileSize) {
        return;
      }
      CreateFileHash(fileToUpload).then((hash) => {
        addFileLog(hash);
      });
    }
  }, []);

  useEffect(() => {
    if (fileSize > 0) {
      if (fileSize > maxFileSize) {
        setShowFileLimitMsg(true);
        setFileState({ fmeUploadStatus: fileStatuses.failed });
        props.onFileStateChange(file.name, fileStatuses.failed);

        return;
      }
      fileUpload();
      setShowProgress(true);
    }
  }, [progress]);

  const fileUpload = () => {
    setCounter(counter + 1);

    if (counter <= chunkCount) {
      var chunk = fileToUpload.slice(beginningOfChunk, endOfChunk);
      uploadChunk(chunk);
    }
  };

  const addFileLog = async (hash) => {
    await axios
      .post(fileLogUrl, undefined, {
        params: {
          fileHash: hash,
          fileName: file.name,
        },
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadChunk = async (chunk) => {
    try {
      let newChunkGuid = uuid4();

      let chunkFormData = new FormData();
      chunkFormData.append("chunk", chunk);

      const response = await axios.post(chunkUrl, chunkFormData, {
        params: {
          fileGuid: newChunkGuid,
          fileName: file.name,
          chunkSize: chunk.size,
        },
        headers: { "Content-Type": "multipart/form-data" },
      });

      const data = response.data;
      if (data.isSuccess) {
        props.onFileGuidMapUpdate(file.name, newChunkGuid);

        setBeginningOfChunk(endOfChunk);
        setEndOfChunk(endOfChunk + maxChunkSize);

        if (counter == chunkCount) {
          await onUploadComplete(file.name).then((resp) => {
            if (resp.data.isSuccess) {
              setProgress(100);
              props.onFileStateChange(file.name, fileStatuses.success);
            }
          });
        } else {
          var percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        }
      } else {
        console.log("Error uploading chunk: ", data.errorMessage);
        props.onFileStateChange(file.name, fileStatuses.failed);
        throw new Error(
          "Error occurred with chunk upload: " + data.errorMessage
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Fragment>
      <tr style={{ height: "100%" }}>
        <td style={{ width: "80px" }}>{file.name}</td>
        <td style={{ display: showProgress ? "" : "none" }}>
          {progressInstance}
        </td>
        <td style={{ display: showFileLimitMsg ? "" : "none" }}>
          <h6 style={{ color: "red" }}>File exceeds 1GB limit.</h6>
        </td>
        <td style={{ display: showHashMismatchMsg ? "" : "none" }}>
          <h6 style={{ color: "red" }}>File Hash Mismatch</h6>
        </td>
        <td>{fileState?.fmeUploadStatus ?? fileStatuses.pending}</td>
      </tr>
    </Fragment>
  );
};

export default FileUploadProgress;
